.headerWrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 1px 1px 14px 1px #0000000d;
  background-color: var(--header-bg-color);
  transition: all 0.8s;
  z-index: 1000;
}
.leftCenterRightContainer {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1400px;
}

.leftRightContainer {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: 90%;
  max-width: 1400px;
}

/* ------------ left Info--------- */
.logo {
  width: 140px;
  object-fit: contain;
  cursor: pointer;
  position: relative;
  top: -2px;
}

/* ------------ center Info--------- */
.centerInfo {
  display: flex;
  justify-content: center;
  gap: 60px;
  align-items: center;
}

/* ------------ right Info--------- */
.rightInfo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(29px, auto));
  justify-content: flex-end;
  align-items: center;
}

/* ----------- last menu button --------- */
.cartContainer {
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cartMenu {
  position: relative;
  top: 1px;
}
.iconContainer {
  position: relative;
  top: 2px;
}
.cartContainer .icon {
  font-size: 20px;
}
.cartContainer p {
  color: var(--black-soft);
  font-size: 21px;
  font-weight: 400;
  font-family: textFont;
  transition: all 0.3s;
}
.cartContainer p:hover {
  color: var(--primary-color);
}
.cartContainer .count {
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 10px;
  background-color: var(--primary-color);
  color: white;
  height: 15px;
  width: 15px;
  display: grid;
  place-items: center;
}

/* ------------------------ media queries ------------------------ */
/* --------------------------------------------------------------- */

@media (max-width: 1440px) {
  .leftRightContainer,
  .leftCenterRightContainer {
    width: 90%;
  }
}
