.menuContainer {
  display: flex;
  align-items: center;
}

/* ------------ menus ----------- */
.menuItem {
  width: max-content;
  color: black;
  text-align: center;
  padding: 1rem;
  transition: background 0.5s;
  height: fit-content;
  cursor: pointer;
  font-size: 21px;
  font-weight: 400;
  font-family: textFont;
  transition: all 0.3s;
}
.menuItem:hover,
.menuItem.active {
  color: var(--primary-color);
}

/* ------------ dropdown menus ----------- */
.dropdownMenu {
  position: relative;
}
.dropdownContainer {
  overflow: hidden;
  height: 0;
  transition: height 0.2s;
  position: absolute;
  top: 55px;
  min-width: 220px;
  text-align: center;
}
.dropdownContainer .menuItem {
  font-weight: 300;
  font-size: 18px;
  font-family: titleFont;
  transition: all 0.3s;
}
.dropdownContainer .section {
  position: relative;
  top: 20px;
  min-width: 300x;
  border: 1px solid #00000012;
  width: 98%;
  background-color: #ffffffa6;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  padding: 10px 0;
}
.dropdownContainer .section .menuItem {
  padding: 10px 10px 10px 50px;
}
.dropdownMenu:hover .dropdownContainer,
.dropdownMenu:focus .dropdownContainer,
.dropdownMenu:focus-within .dropdownContainer {
  height: var(--dropdownHeight);
  z-index: 10000;
}
