.whatsappContainer {
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  background-color: white;
  width: 45px;
  height: 45px;
  z-index: 1;
  display: grid;
  place-items: center;
  text-decoration: none;
}

.whatsappContainer .icon {
  color: green;
  font-size: 30px;
  z-index: 1;
  position: relative;
  top: -1px;
}
