.headerSpaceContainer {
  height: 90px;
}

.mobileHeader {
  display: none;
}

@media (max-width: 768px) {
  .deskHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
  }
}
