.loaderContainer {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  width: 100vw;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 100000000000;
  overflow: hidden;
}

.loaderContainer p {
  font-size: 16px;
  font-family: var(--default-font);
  margin-top: 26px;
  color: var(--black-color);
}

.circle {
  height: 44px;
  width: 44px;
  color: var(--loader-circle-color);
  position: relative;
  display: inline-block;
  border: 2px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
