.headerContainer {
  overflow: hidden;
  position: relative;
}
.HamburgerMenuContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.topHeaderWrapper {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: var(--header-bg-color);
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 150;
  box-shadow: 1px 1px 14px 1px #0000000d;
}
.topHeaderContainer {
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropDownWrapper {
  background-color: var(--header-bg-color);
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  width: 100%;
  transform: translateX(100%);
  overflow: hidden;
  z-index: 150;
  transition: all 0.2s ease-in;
}
.dropDownHide {
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}
.leftInfo {
  display: grid;
  align-items: center;
  width: auto;
  height: 100%;
}
.leftInfo img {
  width: 130px;
  object-fit: contain;
}
.rightInfo {
  color: black;
}
.cartMenu{
  position: relative;
  top: 3px;
}

.rightInfo :global(.hamburger-react div) {
  height: 2px !important;
}

/* ----------------media quries----------------
  --------------------------------------------- */
@media (min-width: 1050px) {
  .topHeaderWrapper {
    display: none;
  }
}
