:root {
  --text-color: var(--black-color-soft);

  --text-letter-spacing: 0px;
  --text-line-height: 25px;

  --text-scale-up-1: 0.2rem;
  --text-scale-up-2: 0.3rem;
  --text-scale-down: 0.1rem;

  --text-font-12: 1.4rem;
  --text-font-14: 1.6rem;
  --text-font-16: 1.8rem;
  --text-font-18: 2rem;
  --text-font-20: 2.2rem;
  --text-font-22: 2.4rem;
  --text-font-24: 2.6rem;
  --text-font-26: 2.8rem;
  --text-font-28: 3rem;
}

.text-12,
.text-14,
.text-16, /* common */
.text-18,
.text-20,
.text-22,
.text-24,
.text-26,
.text-28 {
  font-family: textFont;
  font-weight: var(--font-weight-300);
  color: var(--text-color);
  letter-spacing: var(--text-letter-spacing);
}

/* ---------- text-12 ---------- */
.text-12 {
  font-size: var(--text-font-12);
}
@media (min-width: 1400px) {
  .text-12 {
    font-size: calc(var(--text-font-12) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-12 {
    font-size: var(--text-font-12);
  }
}

@media (max-width: 380px) {
  .text-12 {
    font-size: calc(var(--text-font-12) - var(--text-scale-down));
  }
}

/* ---------- text-14 ---------- */
.text-14 {
  font-size: var(--text-font-14);
}
@media (min-width: 1400px) {
  .text-14 {
    font-size: calc(var(--text-font-14) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-14 {
    font-size: var(--text-font-14);
  }
}

@media (max-width: 380px) {
  .text-14 {
    font-size: calc(var(--text-font-14) - var(--text-scale-down));
  }
}

/* ---------- text-16 ---------- */
.text-16 {
  font-size: var(--text-font-16);
}
@media (min-width: 1400px) {
  .text-16 {
    font-size: calc(var(--text-font-16) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-16 {
    font-size: var(--text-font-16);
  }
}

@media (max-width: 380px) {
  .text-16 {
    font-size: calc(var(--text-font-16) - var(--text-scale-down));
  }
}

/* ---------- text-18 ---------- */
.text-18 {
  font-size: var(--text-font-18);
}
@media (min-width: 1400px) {
  .text-18 {
    font-size: calc(var(--text-font-18) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-18 {
    font-size: var(--text-font-18);
  }
}
@media (max-width: 380px) {
  .text-18 {
    font-size: calc(var(--text-font-18) - var(--text-scale-down));
  }
}

/* ---------- text-20 ---------- */
.text-20 {
  font-size: var(--text-font-20);
}
@media (min-width: 1400px) {
  .text-20 {
    font-size: calc(var(--text-font-20) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-20 {
    font-size: var(--text-font-20);
  }
}
@media (max-width: 380px) {
  .text-20 {
    font-size: calc(var(--text-font-20) - var(--text-scale-down));
  }
}

/* ---------- text-22 ---------- */
.text-22 {
  font-size: var(--text-font-22);
}
@media (min-width: 1400px) {
  .text-22 {
    font-size: calc(var(--text-font-22) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-22 {
    font-size: var(--text-font-22);
  }
}
@media (max-width: 380px) {
  .text-22 {
    font-size: calc(var(--text-font-22) - var(--text-scale-down));
  }
}

/* ---------- text-24 ---------- */
.text-24 {
  font-size: var(--text-font-22);
}
@media (min-width: 1400px) {
  .text-24 {
    font-size: calc(var(--text-font-24) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-24 {
    font-size: var(--text-font-22);
  }
}
@media (max-width: 380px) {
  .text-24 {
    font-size: calc(var(--text-font-24) - var(--text-scale-down));
  }
}

/* ---------- text-26 ---------- */
.text-26 {
  font-size: var(--text-font-26);
}
@media (min-width: 1400px) {
  .text-26 {
    font-size: calc(var(--text-font-26) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-26 {
    font-size: var(--text-font-26);
  }
}
@media (max-width: 380px) {
  .text-26 {
    font-size: calc(var(--text-font-26) - var(--text-scale-down));
  }
}

/* ---------- text-28 ---------- */
.text-28 {
  font-size: var(--text-font-28);
}
@media (min-width: 1400px) {
  .text-28 {
    font-size: calc(var(--text-font-28) + var(--text-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .text-28 {
    font-size: var(--text-font-28);
  }
}
@media (max-width: 380px) {
  .text-28 {
    font-size: calc(var(--text-font-28) - var(--text-scale-down));
  }
}

/* ------------------------ mobile font queries ------------------------ */
/* --------------------------------------------------------------- */
@media (max-width: 460px) {
  .mb-text-12,
  .mb-text-14,
  .mb-text-16, /* common */
  .mb-text-18,
  .mb-text-20,
  .mb-text-22,
  .mb-text-24,
  .mb-text-26,
  .mb-text-28 {
    font-family: textFont;
    font-weight: var(--font-weight-300);
    color: var(--text-color);
    letter-spacing: var(--text-letter-spacing);
  }

  /* ---------- mb-text-12 ---------- */
  .mb-text-12 {
    font-size: var(--text-font-12);
  }
  @media (min-width: 1400px) {
    .mb-text-12 {
      font-size: calc(var(--text-font-12) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-12 {
      font-size: var(--text-font-12);
    }
  }

  @media (max-width: 380px) {
    .mb-text-12 {
      font-size: calc(var(--text-font-12) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-14 ---------- */
  .mb-text-14 {
    font-size: var(--text-font-14);
  }
  @media (min-width: 1400px) {
    .mb-text-14 {
      font-size: calc(var(--text-font-14) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-14 {
      font-size: var(--text-font-14);
    }
  }

  @media (max-width: 380px) {
    .mb-text-14 {
      font-size: calc(var(--text-font-14) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-16 ---------- */
  .mb-text-16 {
    font-size: var(--text-font-16);
  }
  @media (min-width: 1400px) {
    .mb-text-16 {
      font-size: calc(var(--text-font-16) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-16 {
      font-size: var(--text-font-16);
    }
  }

  @media (max-width: 380px) {
    .mb-text-16 {
      font-size: calc(var(--text-font-16) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-18 ---------- */
  .mb-text-18 {
    font-size: var(--text-font-18);
  }
  @media (min-width: 1400px) {
    .mb-text-18 {
      font-size: calc(var(--text-font-18) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-18 {
      font-size: var(--text-font-18);
    }
  }
  @media (max-width: 380px) {
    .mb-text-18 {
      font-size: calc(var(--text-font-18) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-20 ---------- */
  .mb-text-20 {
    font-size: var(--text-font-20);
  }
  @media (min-width: 1400px) {
    .mb-text-20 {
      font-size: calc(var(--text-font-20) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-20 {
      font-size: var(--text-font-20);
    }
  }
  @media (max-width: 380px) {
    .mb-text-20 {
      font-size: calc(var(--text-font-20) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-22 ---------- */
  .mb-text-22 {
    font-size: var(--text-font-22);
  }
  @media (min-width: 1400px) {
    .mb-text-22 {
      font-size: calc(var(--text-font-22) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-22 {
      font-size: var(--text-font-22);
    }
  }
  @media (max-width: 380px) {
    .mb-text-22 {
      font-size: calc(var(--text-font-22) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-24 ---------- */
  .mb-text-24 {
    font-size: var(--text-font-22);
  }
  @media (min-width: 1400px) {
    .mb-text-24 {
      font-size: calc(var(--text-font-24) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-24 {
      font-size: var(--text-font-22);
    }
  }
  @media (max-width: 380px) {
    .mb-text-24 {
      font-size: calc(var(--text-font-24) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-26 ---------- */
  .mb-text-26 {
    font-size: var(--text-font-26);
  }
  @media (min-width: 1400px) {
    .mb-text-26 {
      font-size: calc(var(--text-font-26) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-26 {
      font-size: var(--text-font-26);
    }
  }
  @media (max-width: 380px) {
    .mb-text-26 {
      font-size: calc(var(--text-font-26) - var(--text-scale-down));
    }
  }

  /* ---------- mb-text-28 ---------- */
  .mb-text-28 {
    font-size: var(--text-font-28);
  }
  @media (min-width: 1400px) {
    .mb-text-28 {
      font-size: calc(var(--text-font-28) + var(--text-scale-up-1));
    }
  }
  @media (max-width: 1400px) {
    .mb-text-28 {
      font-size: var(--text-font-28);
    }
  }
  @media (max-width: 380px) {
    .mb-text-28 {
      font-size: calc(var(--text-font-28) - var(--text-scale-down));
    }
  }
}
