/* ---------- swiper slide ---------- */
.swiper-slide {
  width: 300px;
  height: 570px;
  display: grid;
}

/* ---------- navigation ---------- */
.swiper-button-next,
.swiper-button-prev {
  color: var(--secondary-color);
  z-index: 100;
}
.swiper-button-next {
  background-image: url(./arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  right: -5px;
}
.swiper-button-next::after {
  display: none;
}
.swiper-button-prev {
  background-image: url(./arrow.svg);
  background-repeat: no-repeat;
  background-size: 90% auto;
  background-position: center;
  transform: rotate(180deg);
  left: -5px;
}
.swiper-button-prev::after {
  display: none;
}

/* ---------- pagination ---------- */
.swiper-pagination {
  bottom: 4px !important;
}
.swiper-pagination span {
  background: var(--primary-color);
}
