html {
  font-size: 10px;
}
/* * {
  font-family: textFont;
} */

:root {
  /* ---------- color ---------- */
  --primary-color: #b4903a;
  --primary-color-light: #faf8f3;
  --primary-color-dark: #9a792b;

  --secondary-color: #672402;
  --secondary-color-light: #9d3704;
  --secondary-color-dark: #e0e0e0;

  --black-color: black;
  --black-color-soft: #494949;
  --black-color-light: #656565;

  --box-shadow-color: 4px 3px 6px 1px #00000012;

  /* ---------- font family ---------- */
  --default-font: "Poppins";

  /* ---------- font weight ---------- */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
}

/* ---------- default font ---------- */
.default-font {
  font-family: "Poppins";
  font-weight: var(--font-weight-400);
}

/* ---------- colors ---------- */
.primary-color {
  color: var(--primary-color);
}
.primary-color-dark {
  color: var(--primary-color-dark);
}
.secondary-color {
  color: var(--secondary-color);
}

/* ---------- font weight ---------- */
.weight-100 {
  font-weight: var(--font-weight-100);
}
.weight-200 {
  font-weight: var(--font-weight-200);
}
.weight-300 {
  font-weight: var(--font-weight-300);
}
.weight-400 {
  font-weight: var(--font-weight-400);
}
.weight-500 {
  font-weight: var(--font-weight-500);
}

/* ---------- ellipsis ---------- */
.ellipsis {
  line-clamp: 1;
  -webkit-line-clamp: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis.line-2 {
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

/* ----------- wrapper ----------- */
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper-margin {
  margin: 60px 0;
}
.wrapper-margin-top {
  margin-top: 60px;
}
.wrapper-margin-bottom {
  margin-bottom: 60px;
}

.wrapper-padding {
  padding: 60px 0;
}
.wrapper-padding-top {
  padding-top: 60px;
}
.wrapper-padding-bottom {
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .wrapper-margin {
    margin: 40px 0;
  }
  .wrapper-margin-top {
    margin-top: 40px;
  }
  .wrapper-margin-bottom {
    margin-bottom: 40px;
  }

  .wrapper-padding {
    padding: 40px 0;
  }
  .wrapper-padding-top {
    padding-top: 40px;
  }
  .wrapper-padding-bottom {
    padding-bottom: 40px;
  }
}

/* ---------- container ---------- */
.container {
  width: 90%;
  max-width: calc(1400px - 40px);
}

.container-margin {
  margin: 30px 0;
}
.container-margin-top {
  margin-top: 30px;
}
.container-margin-bottom {
  margin-bottom: 30px;
}

.container-padding {
  padding: 30px 0;
}
.container-padding-top {
  padding-top: 30px;
}
.container-padding-bottom {
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .container-margin {
    margin: 20px 0;
  }
  .container-margin-top {
    margin-top: 20px;
  }
  .container-margin-bottom {
    margin-bottom: 20px;
  }

  .container-padding {
    padding: 20px 0;
  }
  .container-padding-top {
    padding-top: 20px;
  }
  .container-padding-bottom {
    padding-bottom: 20px;
  }
}
