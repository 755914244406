.cartContainer {
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  /* padding: 12px 68px 7px 68px; */
  /* border: 1px solid var(--black-color-light); */
  border-radius: 50px;
}
.iconContainer {
  position: relative;
  top: 0px;
}
.cartContainer .icon {
  font-size: 20px;
}
.cartContainer p {
  color: var(--black-soft);
  transition: all 0.3s;
}
.cartContainer p:hover {
  color: var(--primary-color);
}
.cartContainer .count {
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 12px;
  background-color: var(--primary-color);
  color: white;
  height: 16px;
  width: 16px;
  display: grid;
  place-items: center;
  font-family: var(--default-font);
}
