:root {
  --title-color: var(--black-color);

  --title-letter-spacing: 0px;
  --title-line-height: 55px;

  --title-scale-up-1: 0.1rem;
  --title-scale-down-1: 0.2rem;
  --title-scale-down-2: 0.4rem;
  --title-scale-down-3: 0.6rem;
  --title-scale-down-4: 0.8rem;

  --title-font-30: 3rem;
  --title-font-32: 3.2rem;
  --title-font-34: 3.4rem;
  --title-font-36: 3.6rem;
  --title-font-38: 3.8rem;
  --title-font-40: 4rem;
  --title-font-42: 4.2rem;
  --title-font-44: 4.4rem;
}

/* ---------- title font family ---------- */
.title-30,
.title-32, /* common */
.title-34,
.title-36 ,
.title-38,
.title-40,
.title-42,
.title-44 {
  font-family: titleFont;
  color: var(--black-color);
  font-weight: var(--font-weight-500);
  line-height: var(--title-line-height);
  letter-spacing: var(--title-letter-spacing);
}

/* ---------- title-30 ---------- */
.title-30 {
  font-size: var(--title-font-30);
}
@media (min-width: 1400px) {
  .title-30 {
    font-size: calc(var(--title-font-30) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-30 {
    font-size: calc(var(--title-font-30));
  }
}
@media (max-width: 1200px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-down-4));
  }
}

/* ---------- title-32 ---------- */
.title-32 {
  font-size: var(--title-font-32);
}
@media (min-width: 1400px) {
  .title-32 {
    font-size: calc(var(--title-font-32) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-32 {
    font-size: calc(var(--title-font-32));
  }
}
@media (max-width: 1200px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-down-4));
  }
}

/* ---------- title-34 ---------- */
.title-34 {
  font-size: var(--title-font-34);
}
@media (min-width: 1400px) {
  .title-34 {
    font-size: calc(var(--title-font-34) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-34 {
    font-size: calc(var(--title-font-34));
  }
}
@media (max-width: 1200px) {
  .title-34 {
    font-size: calc(var(--title-font-34) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-34 {
    font-size: calc(var(--title-font-34) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-34 {
    font-size: calc(var(--title-font-34) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-34 {
    font-size: calc(var(--title-font-34) - var(--title-scale-down-4));
  }
}

/* ---------- title-36 ---------- */
.title-36 {
  font-size: var(--title-font-36);
}
@media (min-width: 1400px) {
  .title-36 {
    font-size: calc(var(--title-font-36) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-36 {
    font-size: calc(var(--title-font-36));
  }
}
@media (max-width: 1200px) {
  .title-36 {
    font-size: calc(var(--title-font-36) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-36 {
    font-size: calc(var(--title-font-36) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-36 {
    font-size: calc(var(--title-font-36) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-36 {
    font-size: calc(var(--title-font-36) - var(--title-scale-down-4));
  }
}

/* ---------- title-38 ---------- */
.title-38 {
  font-size: var(--title-font-38);
}
@media (min-width: 1400px) {
  .title-38 {
    font-size: calc(var(--title-font-38) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-38 {
    font-size: calc(var(--title-font-38));
  }
}
@media (max-width: 1200px) {
  .title-38 {
    font-size: calc(var(--title-font-38) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-38 {
    font-size: calc(var(--title-font-38) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-38 {
    font-size: calc(var(--title-font-38) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-38 {
    font-size: calc(var(--title-font-38) - var(--title-scale-down-4));
  }
}

/* ---------- title-40 ---------- */
.title-40 {
  font-size: var(--title-font-40);
}
@media (min-width: 1400px) {
  .title-40 {
    font-size: calc(var(--title-font-40) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-40 {
    font-size: calc(var(--title-font-40));
  }
}
@media (max-width: 1200px) {
  .title-40 {
    font-size: calc(var(--title-font-40) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-40 {
    font-size: calc(var(--title-font-40) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-40 {
    font-size: calc(var(--title-font-40) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-40 {
    font-size: calc(var(--title-font-40) - var(--title-scale-down-4));
  }
}

/* ---------- title-42 ---------- */
.title-42 {
  font-size: var(--title-font-42);
}
@media (min-width: 1400px) {
  .title-42 {
    font-size: calc(var(--title-font-42) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-42 {
    font-size: calc(var(--title-font-42));
  }
}
@media (max-width: 1200px) {
  .title-42 {
    font-size: calc(var(--title-font-42) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-42 {
    font-size: calc(var(--title-font-42) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-42 {
    font-size: calc(var(--title-font-42) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-42 {
    font-size: calc(var(--title-font-42) - var(--title-scale-down-4));
  }
}

/* ---------- title-44 ---------- */
.title-44 {
  font-size: var(--title-font-44);
}
@media (min-width: 1400px) {
  .title-44 {
    font-size: calc(var(--title-font-44) + var(--title-scale-up-1));
  }
}
@media (max-width: 1400px) {
  .title-44 {
    font-size: calc(var(--title-font-44));
  }
}
@media (max-width: 1200px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-1));
  }
}
@media (max-width: 760px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-2));
  }
}
@media (max-width: 460px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-3));
  }
}
@media (max-width: 380px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-down-4));
  }
}

/* old code  */
/* 
---------- title 24 ----------
.title-24 {
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-24 {
    font-size: 23px;
  }
}
@media (max-width: 1200px) {
  .title-24 {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .title-24 {
    font-size: 21px;
  }
}
@media (max-width: 460px) {
  .title-24 {
    font-size: 20px;
  }
}
@media (max-width: 380px) {
  .title-24 {
    font-size: 19px;
  }
}

---------- title 26 ----------
.title-26 {
  font-size: 26px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-26 {
    font-size: 25px;
  }
}
@media (max-width: 1200px) {
  .title-26 {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .title-26 {
    font-size: 23px;
  }
}
@media (max-width: 460px) {
  .title-26 {
    font-size: 22px;
  }
}
@media (max-width: 380px) {
  .title-26 {
    font-size: 21px;
  }
}

---------- title 28 ----------
.title-28 {
  font-size: 28px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-28 {
    font-size: 27px;
  }
}
@media (max-width: 1200px) {
  .title-28 {
    font-size: 26px;
  }
}
@media (max-width: 768px) {
  .title-28 {
    font-size: 25px;
  }
}
@media (max-width: 460px) {
  .title-28 {
    font-size: 24px;
  }
}
@media (max-width: 380px) {
  .title-28 {
    font-size: 23px;
  }
}

---------- title 30 ----------
.title-30 {
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-30 {
    font-size: 29px;
  }
}
@media (max-width: 1200px) {
  .title-30 {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .title-30 {
    font-size: 27px;
  }
}
@media (max-width: 460px) {
  .title-30 {
    font-size: 26px;
  }
}
@media (max-width: 380px) {
  .title-30 {
    font-size: 25px;
  }
}

---------- title 34 ----------
.title-34 {
  font-size: 34px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-34 {
    font-size: 32px;
  }
}
@media (max-width: 1200px) {
  .title-34 {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .title-34 {
    font-size: 28px;
  }
}
@media (max-width: 460px) {
  .title-34 {
    font-size: 26px;
  }
}
@media (max-width: 380px) {
  .title-34 {
    font-size: 24px;
  }
}

---------- title 44 ----------
.title-44 {
  font-size: 44px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-44 {
    font-size: 38px;
  }
}
@media (max-width: 1200px) {
  .title-44 {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .title-44 {
    font-size: 34px;
  }
}
@media (max-width: 460px) {
  .title-44 {
    font-size: 32px;
  }
}
@media (max-width: 380px) {
  .title-44 {
    font-size: 30px;
  }
}

---------- title 46 ----------
.title-46 {
  font-size: 46px;
  font-weight: 500;
  line-height: 50px;
}
@media (max-width: 1440px) {
  .title-46 {
    font-size: 44px;
  }
}
@media (max-width: 1200px) {
  .title-46 {
    font-size: 42px;
  }
}
@media (max-width: 768px) {
  .title-46 {
    font-size: 40px;
  }
}
@media (max-width: 460px) {
  .title-46 {
    font-size: 38px;
  }
}
@media (max-width: 380px) {
  .title-46 {
    font-size: 36px;
  }
} */
